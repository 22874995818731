<template>
    <div>
        <div class="login">
            <div class="login-logo">
                <img src="~/_a/images/logo-icon.png" />
                <div style="margin-top: 22px;">银积科技平台</div>
            </div>

            <div class="login-layout animate__animated animate__flipInX">
                <div class="login-container">
                    <div class="login-tabs" v-if="loginType !== $options.loginTypeEnum.FORGET_PASSWORD.key">
                        <div :class="['login-tab-item', loginType === $options.loginTypeEnum.PASSWORD.key ? 'login-tab-item__active' : '']"
                            @click="handleSwitchLoginType($options.loginTypeEnum.PASSWORD.key)">
                            账号登录
                        </div>
                        <div :class="['login-tab-item', loginType === $options.loginTypeEnum.AUTH_CODE.key ? 'login-tab-item__active' : '']"
                            @click="handleSwitchLoginType($options.loginTypeEnum.AUTH_CODE.key)">
                            验证码登录
                        </div>
                    </div>
                    <keep-alive>
                        <component :is="loginComponent"
                                @change-password-success="handleSwitchLoginType($options.loginTypeEnum.PASSWORD.key)"
                                :agreement="agreement"
                                @login-success="handleLoginSuccess"
                                class="login-form">
                        </component>
                    </keep-alive>
                    <div class="login-options">
                        <template v-if="$options.loginTypeEnum.FORGET_PASSWORD.key !== loginType">
                            <Checkbox v-model="agreement"><a href="/agreement.html" target="_blank">同意协议</a></Checkbox>
                            <!--跳转到注册页-->
                            <a href="/register.html" style="margin-right: 10px; margin-left: auto;">注册</a>
                            <span class="login-other"
                                style="margin: 0;"
                                @click="handleSwitchLoginType($options.loginTypeEnum.FORGET_PASSWORD.key)">忘记密码?</span>
                        </template>
                        <template v-else>
                            <span style="margin-left: auto;"
                                class="login-other"
                                @click="handleSwitchLoginType($options.loginTypeEnum.PASSWORD.key)">
                                登录
                            </span>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div class="login-footer">
            粤ICP备19100905号-1  银积科技有限公司版权所有
            <span style="margin-left: 20px;">Copyright © 2018-2035 All Right Reserved</span>
        </div>
    </div>
</template>

<script>
    import { loginTypeEnum, sysTypeEnum } from '_e/index'
    import PasswordLogin from '_c/loginForm/components/PasswordLogin'
    import AuthCodeLogin from '_c/loginForm/components/AuthCodeLogin'
    import ForgetPassword from '_c/loginForm/components/ForgetPassword'

    export default {
        loginTypeEnum,
        title: process.env.VUE_APP_TITLE,
        components: {
            PasswordLogin,
            AuthCodeLogin,
            ForgetPassword
        },
        data () {
            return {
                loginType: loginTypeEnum.PASSWORD.key,
                agreement: true, // 是否同意协议
            }
        },
        computed: {
            loginComponent () {
                switch (this.loginType) {
                    case loginTypeEnum.PASSWORD.key:
                        return this.$options.components.PasswordLogin
                    case loginTypeEnum.AUTH_CODE.key:
                        return this.$options.components.AuthCodeLogin
                    case loginTypeEnum.FORGET_PASSWORD.key:
                        return this.$options.components.ForgetPassword
                    default:
                        break
                }
            },
        },
        methods: {
            /**
             * 切换登录方式
             * @param loginType
             */
            handleSwitchLoginType (loginType) {
                this.loginType = loginType
            },
            /**
             * 登录成功后的处理函数
             */
            handleLoginSuccess (data) {
                this.$store.dispatch('setUser', data)
                // 平台下不需要获取商家列表
                if (process.env.VUE_APP_MODE === sysTypeEnum.PLATFORM.key) {
                    this.$store.dispatch('getMenu')
                    this.$router.push({
                        path: '/'
                    })
                } else {
                    this.$http.get(`/server/v1/User/adminList`).then((response) => {
                        const { code, data } = response
                        if (code === 1 && data && data.length) {
                            this.$store.dispatch('setMerchantList', data)
                            this.$router.push({
                                path: '/merchantList'
                            })
                        } else {
                            this.$Notice.error({
                                title: '登录失败',
                                desc: '无权限访问'
                            })
                        }
                    })
                }
            }
        }
    }
</script>
<style scoped>
    .login {
        height: calc( 100vh - 32px);
        width: 100%;
        min-width: 1000px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url('../../assets/images/login-bg.png');
        background-size: cover;
    }

    .login-logo {
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
        color: #fff;
        font-size: 44px;
    }

    .login-layout {
        max-width: 380px;
        height: 480px;
        display: flex;
        margin-left: 240px;
    }

    .login-tabs {
        width: 100%;
        display: flex;
        padding: 65px 84px 0;
        font-size: 16px;
        justify-content: space-between;
        color: #333;
    }

    .login-tab-item {
        text-align: center;
        padding-bottom: 8px;
        border-bottom: 3px solid transparent;
        cursor: pointer;
    }

    .login-tab-item__active {
        font-weight: bold;
        border-color: #1C92FF;
    }

    .login-container {
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 4px;
        font-size: 14px;
        position: relative;
        transition: all .2s ease-in-out;
        display: flex;
        flex-direction: column;
    }

    .login-form {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 42px 52px 0;
    }

    .login-options {
        display: flex;
        align-items: center;
        padding: 0 52px;
    }

    .login-other {
        margin: 0 10px;
        cursor: pointer;
        transition: color .5s;
    }

    .login-other:hover {
        color: #2d8cf0;
    }

    .login-footer {
        width:100%;
        height: 32px;
        line-height: 32px;
        text-align: center;
        font-size: 12px;
        color: #fff;
        background-color: #0140A3;
    }
</style>
