<template>
    <div>
        <Form ref="loginForm" :model="form" :rules="rules" @keydown.enter.native="handleSubmit" style="width: 373px;">
            <FormItem prop="phone">
                <Input v-model="form.phone" placeholder="请输入手机号码">
            <span slot="prepend">
              <Icon :size="16" type="ios-person"></Icon>
            </span>
                </Input>
            </FormItem>
            <Row :gutter="5">
                <Col span="14">
                    <FormItem prop="verifyCode">
                        <Input v-model="form.verifyCode" placeholder="请输入验证码">
                  <span slot="prepend">
                    <Icon :size="14" type="md-lock"></Icon>
                  </span>
                        </Input>
                    </FormItem>
                </Col>
                <Col span="10" style="display: flex;">
                    <Button style="margin-left: auto;"
                            type="primary"
                            ghost
                            @click="handleGetVerifyCode"
                            v-if="!waitVerifyCode">获取手机验证码
                    </Button>
                    <Button style="margin-left: auto;"
                            disabled
                            v-else>{{waitTime}}秒后可重新获取
                    </Button>
                </Col>
            </Row>
            <FormItem style="margin-top: 48px;">
                <Button type="primary" @click="handleSubmit" long size="large" shape="circle" :disabled="loginDisable" :loading="loading">登录
                </Button>
            </FormItem>
        </Form>
    </div>
</template>

<script>
    import { checkPhone, isEmptyStr } from '@/utils'

    export default {
        name: 'AuthCodeLogin',
        data () {
            return {
                // 等待验证码
                waitVerifyCode: false,
                waitTime: 60,
                loading: false,
                form: {
                    clientType: 0,
                    phone: '',
                    verifyCode: '',
                    verifyKey: ''
                },
                rules: {
                    phone: [
                        { required: true, validator: this.handleValidatePhone.bind(this), trigger: 'blur' }
                    ],
                    verifyCode: [
                        { required: true, message: '验证码不能为空', trigger: 'blur' }
                    ]
                }
            }
        },
        props: {
            agreement: {
                type: Boolean,
                required: true
            }
        },
        computed: {
            // 控制登录按钮是否可用
            loginDisable () {
                return !this.agreement || Object.keys(this.form).some((key) => {
                    return isEmptyStr(this.form[key])
                })
            }
        },
        methods: {
            handleSubmit () {
                this.$refs['loginForm'].validate((valid) => {
                    if (valid) {
                        this.$http.post('/server/user/public/login/phone', this.form).then((response) => {
                            let { code, data, msg: desc } = response
                            if (code === 1) {
                                this.$emit('login-success', data)
                            } else {
                                this.$Notice.success({
                                    title: '登录失败',
                                    desc
                                })
                            }
                        })
                    } else {
                        this.$Notice.error({
                            title: '表单填写错误',
                            desc: '请正确填写表单'
                        })
                    }
                })
            },
            /**
             * 校验手机号码
             * @param rule
             * @param value
             * @param callback
             */
            handleValidatePhone (rule, value, callback) {
                if (!value || isEmptyStr(value)) {
                    callback(new Error('请填写手机号码'))
                } else if (!checkPhone(value)) {
                    callback(new Error('请填写正确格式手机号码'))
                } else {
                    callback()
                }
            },
            /**
             * 获取手机验证码
             */
            handleGetVerifyCode () {
                const phone = this.form.phone
                if (isEmptyStr(phone)) {
                    return this.$Message.error('请填写手机号码')
                }
                if (!checkPhone(phone)) {
                    return this.$Message.error('请填写正确格式的手机号码')
                }
                this.loading = true
                this.handleCountdown()
                this.$http.get(`/server/public/sms/verifyCode`, {
                    params: {
                        phone
                    }
                }).then((response) => {
                    const { code, data } = response
                    if (code === 1) {
                        this.form.verifyKey = data
                        this.$Notice.success({
                            title: '发送成功',
                            desc: `验证码已发送到${phone}`
                        })
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            /**
             * 验证码重新发送倒计时
             */
            handleCountdown () {
                this.waitVerifyCode = true
                const interval = window.setInterval(() => {
                    if (this.waitTime === 0) {
                        this.waitTime = 60
                        this.waitVerifyCode = false
                        window.clearInterval(interval)
                    } else {
                        this.waitTime--
                    }
                }, 1000)
            }
        }
    }
</script>

<style scoped>

</style>
