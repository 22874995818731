<template>
    <div>
        <Form ref="forgetPasswordForm"
              :model="form"
              :rules="rules"
              @keydown.enter.native="handleSubmit"
              style="width: 350px;">
            <FormItem prop="phone">
                <Input v-model="form.phone" placeholder="请输入手机号码">>
                    <Icon slot="prepend" :size="16" type="ios-person"></Icon>
                </Input>
            </FormItem>
            <Row :gutter="5">
                <Col span="14">
                    <FormItem prop="verifyCode">
                        <Input v-model="form.verifyCode" placeholder="请输入验证码">
                            <Icon slot="prepend" :size="14" type="md-lock"></Icon>
                        </Input>
                    </FormItem>
                </Col>
                <Col span="10" style="display: flex;">
                    <Button style="margin-left: auto;" type="primary" ghost @click="handleGetVerifyCode"
                            v-if="!waitVerifyCode">获取手机验证码
                    </Button>
                    <Button style="margin-left: auto;" disabled v-else>{{waitTime}}秒后可重新获取
                    </Button>
                </Col>
            </Row>
            <FormItem prop="password">
                <Input type="password" v-model="form.password" placeholder="请输入密码">
                    <Icon slot="prepend" :size="16" type="ios-unlock-outline"></Icon>
                </Input>
            </FormItem>
            <FormItem prop="confirmPassword">
                <Input type="password" v-model="form.confirmPassword" placeholder="请再次输入新密码">
                    <Icon slot="prepend" :size="16" type="ios-unlock-outline"></Icon>
                </Input>
            </FormItem>
            <FormItem style="margin-top: 50px;">
                <Button type="primary"
                        @click="handleSubmit"
                        long
                        size="large" shape="circle"
                        :disabled="registerDisable"
                        :loading="loading">
                    修改密码
                </Button>
            </FormItem>
        </Form>
    </div>
</template>

<script>
    import { checkPhone, isEmptyStr } from '@/utils'

    export default {
        name: 'ForgetPassword',
        title: process.env.VUE_APP_TITLE,
        data () {
            const validatePass = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请填写密码'))
                } else {
                    if (this.form.confirmPassword !== '') {
                        // 对第二个密码框单独验证
                        this.$refs.forgetPasswordForm.validateField('confirmPassword')
                    }
                    callback()
                }
            }
            const validatePassCheck = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请再次输入新密码'))
                } else if (value !== this.form.password) {
                    callback(new Error('两次输入的密码不一致'))
                } else {
                    callback()
                }
            }
            return {
                loading: false,
                // 等待验证码
                waitVerifyCode: false,
                waitTime: 60,
                form: {
                    phone: '',
                    verifyCode: '',
                    verifyKey: '',
                    clientType: 0,
                    password: '',
                    confirmPassword: ''
                },
                agreement: true,
                rules: {
                    phone: [
                        { required: true, validator: this.handleValidatePhone.bind(this), trigger: 'blur' }
                    ],
                    verifyCode: [
                        { required: true, message: '验证码不能为空', trigger: 'blur' }
                    ],
                    password: [
                        { validator: validatePass, trigger: 'blur' }
                    ],
                    confirmPassword: [
                        { validator: validatePassCheck, trigger: 'blur' }
                    ]
                }
            }
        },
        computed: {
            // 控制注册按钮是否可用
            registerDisable () {
                return !this.agreement || Object.keys(this.form).some((key) => {
                    return isEmptyStr(this.form[key])
                })
            }
        },
        methods: {
            handleSubmit () {
                this.$refs['forgetPasswordForm'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        this.$http.post('/server/user/public/login/forgetPassword', this.form).then((response) => {
                            let { code, msg: desc } = response
                            if (code === 1) {
                                this.$Notice.success({
                                    title: '修改密码成功',
                                    desc: `修改密码成功`
                                })
                                // 修改密码成功后，跳转到登录页
                                this.$emit('change-password-success')
                            } else {
                                this.$Notice.success({
                                    title: '修改密码失败',
                                    desc
                                })
                            }
                        }).finally(() => {
                            this.loading = false
                        })
                    } else {
                        this.$Notice.error({
                            title: '表单填写错误',
                            desc: '请正确填写表单'
                        })
                    }
                })
            },
            /**
             * 校验手机号码
             * @param rule
             * @param value
             * @param callback
             */
            handleValidatePhone (rule, value, callback) {
                if (!value || isEmptyStr(value)) {
                    callback(new Error('请填写手机号码'))
                } else if (!checkPhone(value)) {
                    callback(new Error('请填写正确格式手机号码'))
                } else {
                    callback()
                }
            },
            /**
             * 校验确认密码
             * @param rule
             * @param value
             * @param callback
             */
            handleValidateConfirmPassword (rule, value, callback) {
                if (!value || isEmptyStr(value)) {
                    callback(new Error('请填写确认密码'))
                } else if (!this.form.password || isEmptyStr(this.form.password)) {
                    callback(new Error('请填写密码'))
                } else if (value !== this.form.password) {
                    callback(new Error('确认密码和密码不一致'))
                } else {
                    callback()
                }
            },
            /**
             * 获取手机验证码
             */
            handleGetVerifyCode () {
                const phone = this.form.phone
                if (isEmptyStr(phone)) {
                    return this.$Message.error('请填写手机号码')
                }
                if (!checkPhone(phone)) {
                    return this.$Message.error('请填写正确格式的手机号码')
                }
                this.$http.get(`/server/public/sms/verifyCode`, {
                    params: {
                        phone
                    }
                }).then((response) => {
                    const { code, data } = response
                    if (code === 1) {
                        this.handleCountdown()
                        this.form.verifyKey = data
                        this.$Notice.success({
                            title: '发送成功',
                            desc: `验证码已发送到${phone}`
                        })
                    }
                })
            },
            /**
             * 验证码重新发送倒计时
             */
            handleCountdown () {
                this.waitVerifyCode = true
                const interval = window.setInterval(() => {
                    if (this.waitTime === 0) {
                        this.waitTime = 60
                        this.waitVerifyCode = false
                        window.clearInterval(interval)
                    } else {
                        this.waitTime--
                    }
                }, 1000)
            }
        }
    }
</script>
