var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "login" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "login-layout animate__animated animate__flipInX" },
        [
          _c(
            "div",
            { staticClass: "login-container" },
            [
              _vm.loginType !== _vm.$options.loginTypeEnum.FORGET_PASSWORD.key
                ? _c("div", { staticClass: "login-tabs" }, [
                    _c(
                      "div",
                      {
                        class: [
                          "login-tab-item",
                          _vm.loginType ===
                          _vm.$options.loginTypeEnum.PASSWORD.key
                            ? "login-tab-item__active"
                            : ""
                        ],
                        on: {
                          click: function($event) {
                            return _vm.handleSwitchLoginType(
                              _vm.$options.loginTypeEnum.PASSWORD.key
                            )
                          }
                        }
                      },
                      [_vm._v(" 账号登录 ")]
                    ),
                    _c(
                      "div",
                      {
                        class: [
                          "login-tab-item",
                          _vm.loginType ===
                          _vm.$options.loginTypeEnum.AUTH_CODE.key
                            ? "login-tab-item__active"
                            : ""
                        ],
                        on: {
                          click: function($event) {
                            return _vm.handleSwitchLoginType(
                              _vm.$options.loginTypeEnum.AUTH_CODE.key
                            )
                          }
                        }
                      },
                      [_vm._v(" 验证码登录 ")]
                    )
                  ])
                : _vm._e(),
              _c(
                "keep-alive",
                [
                  _c(_vm.loginComponent, {
                    tag: "component",
                    staticClass: "login-form",
                    attrs: { agreement: _vm.agreement },
                    on: {
                      "change-password-success": function($event) {
                        return _vm.handleSwitchLoginType(
                          _vm.$options.loginTypeEnum.PASSWORD.key
                        )
                      },
                      "login-success": _vm.handleLoginSuccess
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "login-options" },
                [
                  _vm.$options.loginTypeEnum.FORGET_PASSWORD.key !==
                  _vm.loginType
                    ? [
                        _c(
                          "Checkbox",
                          {
                            model: {
                              value: _vm.agreement,
                              callback: function($$v) {
                                _vm.agreement = $$v
                              },
                              expression: "agreement"
                            }
                          },
                          [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "/agreement.html",
                                  target: "_blank"
                                }
                              },
                              [_vm._v("同意协议")]
                            )
                          ]
                        ),
                        _c(
                          "a",
                          {
                            staticStyle: {
                              "margin-right": "10px",
                              "margin-left": "auto"
                            },
                            attrs: { href: "/register.html" }
                          },
                          [_vm._v("注册")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "login-other",
                            staticStyle: { margin: "0" },
                            on: {
                              click: function($event) {
                                return _vm.handleSwitchLoginType(
                                  _vm.$options.loginTypeEnum.FORGET_PASSWORD.key
                                )
                              }
                            }
                          },
                          [_vm._v("忘记密码?")]
                        )
                      ]
                    : [
                        _c(
                          "span",
                          {
                            staticClass: "login-other",
                            staticStyle: { "margin-left": "auto" },
                            on: {
                              click: function($event) {
                                return _vm.handleSwitchLoginType(
                                  _vm.$options.loginTypeEnum.PASSWORD.key
                                )
                              }
                            }
                          },
                          [_vm._v(" 登录 ")]
                        )
                      ]
                ],
                2
              )
            ],
            1
          )
        ]
      )
    ]),
    _vm._m(1)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "login-logo" }, [
      _c("img", { attrs: { src: require("_a/images/logo-icon.png") } }),
      _c("div", { staticStyle: { "margin-top": "22px" } }, [
        _vm._v("银积科技平台")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "login-footer" }, [
      _vm._v(" 粤ICP备19100905号-1 银积科技有限公司版权所有 "),
      _c("span", { staticStyle: { "margin-left": "20px" } }, [
        _vm._v("Copyright © 2018-2035 All Right Reserved")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }