<template>
    <div>
        <Form ref="loginForm" :model="form" :rules="rules" @keydown.enter.native="handleSubmit" style="width: 373px;">
            <FormItem prop="userName">
                <Input v-model="form.userName" placeholder="请输入用户名">
            <span slot="prepend">
              <Icon :size="16" type="ios-person"></Icon>
            </span>
                </Input>
            </FormItem>
            <FormItem prop="password">
                <Input type="password" v-model="form.password" placeholder="请输入密码">
            <span slot="prepend">
              <Icon :size="14" type="md-lock"></Icon>
            </span>
                </Input>
            </FormItem>
            <Row :gutter="5">
                <Col span="16">
                    <FormItem>
                        <Input v-model="form.verifyCode" placeholder="请输入图像验证码">
                <span slot="prepend">
                  <Icon :size="16" type="ios-keypad"></Icon>
                </span>
                        </Input>
                    </FormItem>
                </Col>
                <Col span="8">
                    <img :src="verifyCodeImage" @click="handleGetVerifyCode">
                </Col>
            </Row>
            <FormItem style="margin-top: 48px;">
                <Button type="primary" @click="handleSubmit" long size="large" shape="circle" :disabled="loginDisable" :loading="loading">登录
                </Button>
            </FormItem>
        </Form>
    </div>
</template>

<script>
    import { isEmptyStr, cacheStorage } from '@/utils'

    export default {
        name: 'PasswordLogin',
        data () {
            return {
                loading: false,
                form: {
                    clientType: 0,
                    password: '',
                    userName: '',
                    verifyCode: '',
                    verifyCodeSession: '',
                },
                verifyCodeImage: '',
                rules: {
                    userName: [
                        { required: true, message: '用户名不能为空', trigger: 'blur' }
                    ],
                    password: [
                        { required: true, message: '密码不能为空', trigger: 'blur' }
                    ]
                }
            }
        },
        props: {
            agreement: {
                type: Boolean,
                required: true
            }
        },
        computed: {
            // 控制注册按钮是否可用
            loginDisable () {
                return Object.keys(this.form).some((key) => {
                    if (key === 'verifyCodeSession') {
                        return false
                    }
                    return isEmptyStr(this.form[key])
                })
            }
        },
        created () {
            this.handleGetVerifyCode()
        },
        methods: {
            handleSubmit () {
                if (!this.agreement) {
                    this.$Notice.warning({
                        title: '警告',
                        desc: '请勾选同意协议!'
                    })
                    return
                }
                this.$refs['loginForm'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        this.form.verifyCodeSession = cacheStorage.getItem(process.env.VUE_APP_VERIFY_CODE_KEY)
                        this.$http.post('/server/user/public/login/username', this.form).then((response) => {
                            let { code, data, msg: desc } = response
                            if (code === 1) {
                                this.$emit('login-success', data)
                            } else {
                                this.$Notice.success({
                                    title: '登录失败',
                                    desc
                                })
                            }
                        }).finally(() => {
                            this.loading = false
                        })
                    } else {
                        this.$Notice.error({
                            title: '表单填写错误',
                            desc: '请正确填写表单'
                        })
                    }
                })
            },
            handleGetVerifyCode () {
                this.$http.get(`/server/public/chart/verifyCode`, {
                    params: {
                        width: 120,
                        height: 35,
                        lineSize: 10
                    }
                }).then((response) => {
                    const { code, data } = response
                    if (code === 1) {
                        this.verifyCodeImage = data
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
