<template>
  <login-form />
</template>

<script>
import LoginForm from '_c/loginForm/LoginForm'
export default {
  components: {
    LoginForm
  },
}
</script>
